import React, { ReactElement, useEffect, useState } from 'react';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import { usePageTheme, useApp, useSystemMode } from '@Context/AppContext';
import '@GraphQL/fragments';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import Text from '@Src/components/Text';

export interface Props extends IPageProps {
  data: {
    contentfulPageTypePitch: IPageData;
  };
  locale?: string
  path?: string
}

const AdvanceInquiryIneligiblePage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
  //const { title, seoSettings, content } = data.contentfulPageTypePitch;
  const title = ''
  const { setTheme: setPageTheme } = usePageTheme();
  const { mode, pageType, setPageType, setPageTitle } = useApp();

  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  const [advanceInquiryId, setAdvanceInquiryId] = useState<string | null | undefined>(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const query = new URLSearchParams(window.location.search)
      setAdvanceInquiryId(query.get('advanceInquiryId')!)
    }
  })
  useEffect(() => {
    if (pageType === 'index' && transitionStatus == 'exiting') {
      setLocalTransStatus(transitionStatus + 'Index');
    } else {
      setLocalTransStatus(transitionStatus);
    }

    if (transitionStatus == 'entering' || transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
      setPageTitle(title);
      setPageType('advance');
    }
  }, [transitionStatus]);
  useEffect(() => {
    if (transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
    }
  }, [mode]);

  return (
    <ContentLayout type={pageType} transitionState={localTransStatus} fullWidth>
      <div style={{ padding: 0 }}>
        <Text theme={mode} heading01={`Thanks for your request`} />
        <Text theme={mode} htmlText={`Sorry but it seems your artist doesn't yet meet the qualification for an estimate yet.`} />
      </div>
    </ContentLayout>
  );
};

export default AdvanceInquiryIneligiblePage;